import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import AllFaq from "../components/allFaq";
import { graphql } from "gatsby";

const seoData = {
    title: "ONAYLARIM",
    description: "Soru ve cevaplar.",
    slug: "",
};

const ExtendedFaq = () => {
    return (
        <Layout pageTitle="Soru ve Cevaplar">
            <Seo title={seoData.title} description={seoData.description || seoData.excerpt || "nothin’"} pathname={seoData.slug} article />
            <AllFaq></AllFaq>
        </Layout>
    );
};

export default ExtendedFaq;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
