import * as React from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

const faqs = [
  {
    question: "e-İmza Nedir",
    answer:
      "e-İmza, elektronik ortamda bulunan bir belgeye (örnek olarak bilgisayarınızdaki bir Word dosyası gibi düşünebilirseiniz) eklenerek imza atan kişinin kim olduğunu, hukuki anlamda reddedilemez bir biçimde gösteren veridir. 5070 Sayılı Elektronik İmza Kanunu'na göre e-İmza, elle atılan imza ile aynı hukuksal geçerliliğe sahiptir.",
  },
  {
    question: "Elektronik imzanın hukuki sonuçları nedir?",
    answer:
      "Elektronik İmza Kanununda, güvenli elektronik imza, elle atılan imzaya eşdeğer kabul edilmiş ve güvenli elektronik imza ile oluşturulmuş verilerin senet hükmünde olacağı, bu verilerin aksi ispat edilinceye kadar kesin delil sayılacağı belirtilmiştir.",
  },
  {
    question: "Nereden e-imza alabilirim?",
    answer:
      "Güvenli Elektronik İmza kullanmak için gerekli olan nitelikli elektronik sertifika https://www.btk.gov.tr/elektronik-sertifika-hizmet-saglayicilari adresinde listelenen yetkilendirilmiş elektronik sertifika hizmet sağlayıcılarından birinden satın alınabilir.",
    styled: (
      <>
        <p className="text-base text-gray-500">
          Güvenli Elektronik İmza kullanmak için gerekli olan nitelikli
          elektronik sertifika{" "}
          <a
            className="underline text-sky-700 hover:text-sky-600 whitespace-nowrap"
            href="https://www.btk.gov.tr/elektronik-sertifika-hizmet-saglayicilari"
            target="_blank"
            rel="noreferrer"
          >
            yetkilendirilmiş elektronik sertifika hizmet sağlayıcılardan
          </a>{" "}
          herhangi birinden satın alınabilir.
        </p>
      </>
    ),
  },
  {
    question: "ONAYLARIM'da imzalanan belgeleri nasıl doğrulayabilirim?",
    answer:
      "İmzalı belgelerin üzerinde bulunan doğrulama adresi ve koduyla belge üzerindeki e-imzalar doğrulanabilir. Ayrıca e-imzalı belgeleri Tübitak KamuSM web sayfasından ücretsiz olarak indirebileceğiniz İmzager Kurumsal ile de doğrulayabilirsiniz. ",
    styled: (
      <>
        <p className="text-base text-gray-500">
          İmzalı belgelerin üzerinde bulunan doğrulama adresi ve koduyla belge
          üzerindeki e-imzalar doğrulanabilir. Ayrıca e-imzalı belgeleri Tübitak
          KamuSM web sayfasından ücretsiz olarak indirebileceğiniz{" "}
          <a
            className="underline text-sky-700 hover:text-sky-600 whitespace-nowrap"
            href="https://yazilim.kamusm.gov.tr/?q=/node/143"
            target="_blank"
            rel="noreferrer"
          >
            İmzager Kurumsal
          </a>{" "}
          ile de doğrulayabilirsiniz.
        </p>
      </>
    ),
  },
];

// const htmlDecode = (input) => {
//     var e = document.createElement("div");
//     e.innerHTML = input;
//     return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
// };

const faqsPurchase = [
  {
    question: "Ücretsiz kullanım mümkün mü?",
    answer:
      "Deneme sürümü ile sistemi ücretsiz olarak kullanabilirsiniz. Deneme sürümünde 5 süreç oluşturabilirsiniz. Deneme sürümüyle diğer planlar arasında, atılan e-imzaların yasal geçerliği bakımından hiçbir fark bulunmamaktadır. Deneme sürümünde e-imzalı belgeleriniz size saklamanız için e-posta olarak gönderildikten sonra sistemden silinir.",
  },
];

const faqsPurchasePaid = [
  {
    question: "Hangi durumda ücretli plana geçmem gerekir?",
    answer:
      "Bu soruya kişisel kullanıcılar ve kurumsal kullanıcılar için 2 ayrı ",
    styled: (
      <>
        <p className="text-base text-gray-500">
          Bu soruya kişisel kullanıcılar ve kurumsal kullanıcılar için 2 ayrı
          cevap vermek gerekiyor.
        </p>
        <div className="text-base text-gray-500 mt-4">
          <div className="font-semibold whitespace-nowrap">
            Kişisel hesaplar
          </div>
          <div>
            Ücretsiz bir kişisel hesabınızla, ücretli kişisel hesabın yaptığı
            tüm işlemleri yapabilirsiniz.
          </div>
          <div>
            Ücretsiz hesaplarda belgeler e-imzalandıktan sonra size e-posta
            olarak gönderilir. e-Postada gönderilen Pdf hukuken geçerli e-imzalı
            bir belgedir.
          </div>
          <div>
            Bu belgeyi sizin güvenli bir yerde saklamanız gerekir. e-İmza
            işleminden 3 gün sonra e-imzalı belge sistemden silinir.
          </div>
          <div>
            <span className="underline">Ücretli hesaba</span> geçtiğinizde
            e-imzalı belgeleriniz ONAYLARIM tarafından güvenli bulutta saklanır.
            Belgeye istediğiniz an istediğiniz yerden erişebilirsiniz.
          </div>
          <div className="font-semibold whitespace-nowrap mt-4">
            Kurumsal hesaplar
          </div>
          <div>
            Ücretsiz bir kurumsal hesabınızla, ücretli kurumsal hesabın yaptığı
            tüm işlemleri yapabilirsiniz.
          </div>
          <div>
            Ücretsiz hesaplarda belgeler e-imzalandıktan sonra size e-posta
            olarak gönderilir. e-Postada gönderilen Pdf hukuken geçerli e-imzalı
            bir belgedir.
          </div>
          <div>
            Bu belgeyi sizin güvenli bir yerde saklamanız gerekir. e-İmza
            işleminden 3 gün sonra e-imzalı belge sistemden silinir.
          </div>
          <div>
            <span className="underline">Ücretli hesaba</span> geçtiğinizde
            e-imzalı belgeleriniz ONAYLARIM tarafından güvenli bulutta saklanır.
            Belgeye istediğiniz an istediğiniz yerden erişebilirsiniz.
          </div>
          <div>
            Ücretli kurumsal hesaplarda, şirketinizdeki diğer kişileri de
            sisteme dahil edebilir, kurumunuzun organizasyon yapısına uygun
            şekilde birimler oluşturabilir ve kullanıcılara birim seviyesinde
            yetkiler verebilirsiniz.
          </div>
        </div>
      </>
    ),
  },
  // {
  //     question: "Tek seferlik ödeme ve krediden ödemenin farkı nedir?",
  //     answer: "ONAYLARIM, en uygun fiyatlı çözüm olabilmek adına, kullandığın kadar öde yöntemiyle çalışmaktadır. Bir diğer deyişle, bir süreç başlatmak istediğinizde ödeme yapmanız gerekir. Ödeme adımında 2 seçenek bulunur. Tek seferlik ödeme ve krediden ödeme. Tek seferlik ödeme, yılda en fazla 5 süreç oluşturacak kişiler için avantajlıyken, daha fazla süreç oluşturacak kişiler için krediden ödeme daha avantajlıdır. Kredi alarak süreç başına ödediğiniz miktarı en aza indirebilirsiniz.",
  // },
  {
    question: "Kredi almak için ne yapmam gerekir?",
    answer:
      "Üye olduktan sonra tüm işlemleri uygulama içinden kendiniz yapabilirsiniz.",
  },
  {
    question: "Kredi aldığımda hemen hesabıma geçer mi?",
    answer:
      "Kredi kartı ile ödeme yaptığınız anda krediniz hesabınıza geçer. Havale/EFT ile yapılan ödemelerde mesai saatler içinde 2 saat, diğer zamanlarda ise 24 saat içinde krediniz hesabınıza geçer.",
  },
  {
    question: "Ödeme yöntemleri nedir?",
    answer:
      "Kredi kartı ve havale/EFT yöntemleri bulunmaktadır. Kredi kartı ile yapılan ödemeler güvenli PayTR altyapısı kullanılarak yapılır. ONAYLARIM hiçbir şekilde kredi kartı bilgileriniz almaz ya da saklamaz.",
  },
  // {
  //     question: "Kurumsal hesaba yükseltmek için nasıl ödeme yapabilirim?",
  //     answer: "Kredi kartı ve havale/EFT ile ödeme yapabilirsiniz. Kredi kartı ile ödeme yaptığınız anda hesabınız kurumsal hesaba yükseltilir. Havale/EFT ile yapılan ödemelerde mesai saatler içinde 2 saat, diğer zamanlarda ise 24 saat içinde hesap yükseltme işlemi gerçekleşir.",
  // },
  {
    question: "Kurumsal hesaba yükseltmek için nasıl ödeme yapabilirim?",
    answer:
      "Sadece ihtiyacınız olan eklentileri alarak en uygun maliyetle kurumsal hesaba geçiş işlemleri için lütfen bize ulaşınız.",
  },

  {
    question:
      "Şirketimizdeki diğer kişileri sisteme dahil etmek için ne yapmam gerekir?",
    answer:
      "Hesabınızı kurumsal hesaba yükselterek şirketinizdeki diğer kişileri sisteme dahil edebilirsiniz.",
  },
  {
    question: "Ek kurumsal kullanıcı lisansı almak için ne yapmam gerekir?",
    answer: "Ek kullanıcı lisansı alma işlemleri için lütfen bize ulaşınız.",
  },
];

const faqsProduct = [
  {
    question: "ONAYLARIM ne kadar güvenli?",
    answer:
      "Verini yurtiçinde konumlandırılmış bulut sunucularda saklanır. Belgelerinize sizden başka hiçbir kişi erişemez. Ayrıca verilerini kendi sunucularında bulundurmak isteyen kurumlar için özel olarak geliştirdiğimiz On Premise çözümümüz de bulunmaktadır.",
  },
  {
    question: "Hesabımı birden fazla kişi kullanabilir mi?",
    answer:
      "Kullanıcı adı ve şifrenizi başka bir kişiyle paylaşabilirsiniz. Ancak bu durumda bir başkası sizin adınıza işlemler yaparak istenmeyen sonuçlar doğurabilir. Birden çok kişinin çalıştığı şirket, kurum gibi yapılar için hesabınızı kurumsal hesaba yükselterek gelişmiş yetkilendirme özelliklerini kullanabilirsiniz.",
    styled: (
      <>
        <p className="text-base text-gray-500">
          Kullanıcı adı ve şifrenizi başka bir kişiyle paylaşabilirsiniz. Ancak
          bu durumda bir başkası sizin adınıza işlemler yaparak istenmeyen
          sonuçlar doğurabilir. Birden çok kişinin çalıştığı şirket, kurum gibi
          yapılar için hesabınızı{" "}
          <a
            className="inline underline text-sky-700 hover:text-sky-600 whitespace-nowrap"
            href="https://guide.onaylarim.com/docs/account-types/enterprise-account"
            target="_blank"
            rel="noreferrer"
          >
            kurumsal hesaba
          </a>{" "}
          yükselterek gelişmiş yetkilendirme özelliklerini kullanabilirsiniz.
        </p>
      </>
    ),
  },
  {
    question:
      "Belgeleri e-imzaya göndereceğim kişilerin üye olması veya ödeme yapması gerekiyor mu?",
    answer:
      "Hayır. Belgeleri e-imzaya göndereceğim kişilerin üye olmasına veya ödeme yapmasına gerek yoktur. Sadece süreç oluşturup diğer kişilere e-imza için gönderen hesapların üye olması gerekir.",
  },
  {
    question: "Dokümanlarımı başka biri görebilir mi?",
    answer:
      "Kişisel hesaplar için, dokümanlarınızı başka bir kişinin görmesi mümkün değildir. Kurumsal hesaplarda, dokümanları yetkilendirilmiş diğer sistem kullanıcıları görebilir.",
  },
  {
    question:
      "ONAYLARIM ile e-imzalanmış bir belgenin hukuki geçerliliği var mıdır?",
    answer:
      "Evet. e-İmza ile imzalanmış belgelerdeki e-imza, ıslak imza ile eş değerdedir.",
  },
  {
    question: "Ücretsiz üye olduğumda belgemdeki e-imza hukuken geçerli midir?",
    answer:
      "Evet. Ücretsiz üyelikle hukuki anlamda geçerli e-imzalı belgeler oluşturabilirsiniz. Örnek olarak ücretsiz üye olarak bir havale talimatı oluşturup çalıştığınız bankaya gönderebilisiniz.",
  },
  {
    question:
      "Ücretsiz üyelikle bir kişiye belge gönderdiğimde ilgili kişi belgeyi imzalayabilir mi?",
    answer:
      "Evet. Ücretsiz üyelikle oluşturduğunuz belgelere sizin belirlediğiniz kişiler e-imza atabilir.",
  },
  {
    question: "Nasıl destek alabilirim?",
    answer:
      "Bunun için 3 yöntem sunuyoruz. 1. Dokümanları inceleyebilirsiniz. 2. Bize e-posta gönderebilirsiniz. 3. Sağ alttaki canlı sohbet ile bizle iletişime geçebilirsiniz.",
    styled: (
      <>
        <p className="text-base text-gray-500">
          Bunun için 3 yöntem sunuyoruz.
        </p>
        <ul className="text-base text-gray-500 list-disc list-inside mt-2">
          <li>
            <a
              className="underline text-sky-700 hover:text-sky-600 whitespace-nowrap"
              href="https://guide.onaylarim.com/docs/tutorial-how-to/flowcreation"
              target="_blank"
              rel="noreferrer"
            >
              Dokümanları
            </a>{" "}
            inceleyebilirsiniz.
          </li>
          <li>
            Bize{" "}
            <a
              className="underline text-sky-700 hover:text-sky-600 whitespace-nowrap"
              href="mailto:destek@onaylarim.com"
              target="_blank"
              rel="noreferrer"
            >
              e-posta
            </a>{" "}
            gönderebilirsiniz.
          </li>
          <li>Sağ alttaki canlı sohbet ile bizle iletişime geçebilirsiniz.</li>
        </ul>
      </>
    ),
  },
  {
    question: "Kişisel ve kurumsal hesap arasındaki farklar nelerdir?",
    answer:
      "Kişisel hesaplar, sadece 1 kişi tarafından yönetilen hesaplardır. Kurumsal hesap ise ONAYLARIM'ın bir şirket, kurum ya da kuruluş tarafından kullanılması durumunda kullanılması gereken hesap türüdür. Kurumsal hesapta, kuruma ait birimler, kullanıcılar ve roller tanımlanabilir.",
    styled: (
      <>
        <p className="text-base text-gray-500">
          Kişisel hesaplar, sadece 1 kişi tarafından yönetilen hesaplardır.
          Kurumsal hesap ise ONAYLARIM'ın bir şirket, kurum ya da kuruluş
          tarafından kullanılması durumunda kullanılması gereken hesap türüdür.
          Kurumsal hesapta, kuruma ait birimler, kullanıcılar ve roller
          tanımlanabilir. Daha fazla bilgi için dokümanlardaki{" "}
          <a
            className="inline underline text-sky-700 hover:text-sky-600 whitespace-nowrap"
            href="https://guide.onaylarim.com/docs/account-types/trial-info"
            target="_blank"
            rel="noreferrer"
          >
            hesap türleri
          </a>{" "}
          sayfasını inceleyebilirsiniz.
        </p>
      </>
    ),
  },
  {
    question: "Sunum yapabilir misiniz?",
    answer:
      "Tabi. e-Posta, telefon veya sağ alttaki canlı sohbetten bizimle iletişime geçerseniz ve uygun bir zaman için toplantı planlayabiliriz.",
    styled: (
      <>
        <p className="text-base text-gray-500">
          Tabi.{" "}
          <a
            className="underline text-sky-700 hover:text-sky-600 whitespace-nowrap"
            href="mailto:destek@onaylarim.com"
            target="_blank"
            rel="noreferrer"
          >
            e-Posta
          </a>{" "}
          , telefon (0 312 265 09 15) veya sağ alttaki canlı sohbetten bizimle
          iletişime geçerseniz ve uygun bir zaman için toplantı planlayabiliriz.
        </p>
      </>
    ),
  },
  {
    question: "Başlattığım süreci iptal edebilir miyim?",
    answer: "Evet. Süreç devam ederken süreci iptal edebilirsiniz.",
  },
];

const AllFaq = () => {
  return (
    <div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between">
          <div className="max-w-xl">
            <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Soru ve Cevaplar
            </h2>
            <p className="mt-5 text-xl text-gray-500">
              ONAYLARIM'ı kullanmadan önce aklınıza gelebilecek tüm soruları
              yanıtlamaya çalıştık. Cevap bulamadığınız sorular için lütfen sağ
              alttaki canlı sohbetten bize ulaşın.
            </p>
          </div>
          <div className="mt-10 w-full max-w-xs hidden">
            <label
              htmlFor="currency"
              className="block text-base font-medium text-gray-500"
            >
              Currency
            </label>
            <div className="mt-1.5 relative">
              <select
                id="currency"
                name="currency"
                className="appearance-none block w-full bg-none bg-white border border-gray-300 rounded-md pl-3 pr-10 py-2 text-base text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                defaultValue="United States (USD)"
              >
                <option>Argentina (ARS)</option>
                <option>Australia (AUD)</option>
                <option>United States (USD)</option>
                <option>Canada (CAD)</option>
                <option>France (EUR)</option>
                <option>Japan (JPY)</option>
                <option>Nigeria (NGN)</option>
                <option>Switzerland (CHF)</option>
                <option>United Kingdom (GBP)</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 px-2 flex items-center">
                <ChevronDownIcon
                  className="h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 divide-y divide-gray-200 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl font-extrabold text-gray-900">
            e-İmza ile İlgili Sorular
          </h2>
          <div className="mt-8">
            <dl className="divide-y divide-gray-200">
              {faqs.map((faq, faqIdx) => (
                <div
                  key={"faq" + faqIdx}
                  className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8"
                >
                  <dt className="text-lg sm:text-base font-medium text-gray-900 md:col-span-5">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 md:mt-0 md:col-span-7">
                    {faq.styled ? (
                      faq.styled
                    ) : (
                      <p className="text-base text-gray-500">{faq.answer}</p>
                    )}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="max-w-7xl mx-auto py-12 px-4 divide-y divide-gray-200 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl font-extrabold text-gray-900">
            Ürün ile İlgili Sorular
          </h2>
          <div className="mt-8">
            <dl className="divide-y divide-gray-200">
              {faqsProduct.map((faq, faqIdx) => (
                <div
                  key={"faq" + faqIdx}
                  className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8"
                >
                  <dt className="text-lg sm:text-base font-medium text-gray-900 md:col-span-5">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 md:mt-0 md:col-span-7">
                    <dd className="mt-2 md:mt-0 md:col-span-7">
                      {faq.styled ? (
                        faq.styled
                      ) : (
                        <p className="text-base text-gray-500">{faq.answer}</p>
                      )}
                    </dd>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="max-w-7xl mx-auto py-12 px-4 divide-y divide-gray-200 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl font-extrabold text-gray-900">
            Ücretsiz Planlarla İlgili Sorular
          </h2>
          <div className="mt-8">
            <dl className="divide-y divide-gray-200">
              {faqsPurchase.map((faq, faqIdx) => (
                <div
                  key={"faq" + faqIdx}
                  className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8"
                >
                  <dt className="text-lg sm:text-base font-medium text-gray-900 md:col-span-5">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 md:mt-0 md:col-span-7">
                    <dd className="mt-2 md:mt-0 md:col-span-7">
                      {faq.styled ? (
                        faq.styled
                      ) : (
                        <p className="text-base text-gray-500">{faq.answer}</p>
                      )}
                    </dd>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
        <div className="max-w-7xl mx-auto py-12 px-4 divide-y divide-gray-200 sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-3xl font-extrabold text-gray-900">
            Ücretli Planlarla İlgili Sorular
          </h2>
          <div className="mt-8">
            <dl className="divide-y divide-gray-200">
              {faqsPurchasePaid.map((faq, faqIdx) => (
                <div
                  key={"faqPaid" + faqIdx}
                  className="pt-6 pb-8 md:grid md:grid-cols-12 md:gap-8"
                >
                  <dt className="text-lg sm:text-base font-medium text-gray-900 md:col-span-5">
                    {faq.question}
                  </dt>
                  <dd className="mt-2 md:mt-0 md:col-span-7">
                    <dd className="mt-2 md:mt-0 md:col-span-7">
                      {faq.styled ? (
                        faq.styled
                      ) : (
                        <p className="text-base text-gray-500">{faq.answer}</p>
                      )}
                    </dd>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllFaq;
